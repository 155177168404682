<template>
  <div class="container-xxl">
        <h5>最新公告</h5>
        <a-table
            :columns="columns"
            :row-key="record => record.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            bordered
        >
            <!--<template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }} </template>         
            <a slot="typ" slot-scope="typ,obj">                                                    
              <router-link :to="{name:'Info_detail',params:{id:obj.id}}">{{ typ }}</router-link>
            </a>-->
            <template slot="typ" slot-scope="typ"> 
            <div v-show="typ=='alert'"><b-icon icon="bell-fill" variant="warning" font-scale="1.5" ></b-icon>&ensp;訊息</div>
            <div v-show="typ=='mess'"><b-icon icon="chat-quote-fill" variant="success" font-scale="1.5"></b-icon>&ensp;公告</div>
            </template>
            <template slot="states" slot-scope="states,obj"> 
            <div v-show="states=='N'"><span class="text-danger" @click="updatestatus(obj.id)" style="cursor: pointer;">未讀取</span></div>
            <div v-show="states=='Y'"><span class="text-success">已讀取</span></div>
            </template>            
            <template slot="created_at" slot-scope="created_at"> {{ fdateformat(created_at) }}</template>
            <a slot="action" slot-scope="obj">
            <router-link :to="{name:'Info_detail',params:{id:obj.id}}">內容...</router-link>
            </a>
        </a-table>
  </div>
  
</template>
<script>
import { messService } from '../../_services/'
import * as moment from "moment/moment";
import { mapState,mapMutations } from "vuex";

const queryData = params => {
  //console.log("params:",params)
  return messService.finditems(params.page,params.pid) 
};  

const updatedata = obj => {
  return  messService.upstatus(obj)
}
const columns = [
  {
    title: '訊息類別',
    dataIndex: 'typ',
    width: '10%',    
    scopedSlots: { customRender: 'typ' },    
  }, 
  {
    
    title: '狀態',
    dataIndex: 'states',
    width: '10%',    
    scopedSlots: { customRender: 'states' },     
  },  
  {
    title: '發送者',
    dataIndex: 'fname',
    sorter: false,
    width: '15%',
    scopedSlots: { customRender: 'fname' },
  },
  {
    title: '標題',
    dataIndex: 'title',
    width: '30%',
  },   
  {
    title: '刊登日期',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'created_at' },    
    width: '15%',
  }, 
  { 
    title: '內文', 
    dataIndex: '', 
    key: 'id', 
    scopedSlots: { customRender: 'action' } ,
    width: '10%',
    },   

];
export default {
  name: 'Info_center',    
  data() {
    return {
      data: [],
      pagination: {defaultPageSize:4},
      loading: false,
      columns,
      msg:'',
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {
    this.fetch({results:4,page:1,pageSize: 4,pid:this.user.data.user.id });
  },
  methods: {
    ...mapMutations('ws',['setChang']),
    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      //console.log(this.pagination);
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        pid: this.user.data.user.id,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      queryData({
        //results: 4,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination; 
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    updatestatus(ids){
      let obj ={id: ids}
      updatedata(obj).then(({data}) =>{
        if(data.rs=='done'){
          this.setChang(obj)
          this.data.find(obj => obj.id == data.id).states = "Y"
        }
      });
    },
  
  },
};
</script>